import cls from 'classnames'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-hook-inview'
import { Survey } from 'survey-react'
import { graphql, useFragment } from 'react-relay'

const WORDPRESS_BASE_URL = 'https://how2-research-wp.pxw-lab.de' // 'http://0.0.0.0' // for local testing
const GET_SURVEY_URL = `${WORDPRESS_BASE_URL}/wp-admin/admin-ajax.php?action=SurveyJS_GetSurveyJson`
const POST_RESULT_URL = `${WORDPRESS_BASE_URL}/wp-admin/admin-ajax.php?action=SurveyJS_SaveResult`

const FeedItemBenchmark = ({ benchmark }) => {
  const data = useFragment(
    graphql`
      fragment FeedItemBenchmark_benchmark on How2Post_How2postdata_Benchmark {
        surveyscreen {
          survey

          image {
            mediaDetails {
              sizes {
                name
                sourceUrl
              }
            }
          }
        }

        resultscreen {
          headline
          text
          image {
            mediaDetails {
              sizes {
                name
                sourceUrl
              }
            }
          }
        }
      }
    `,
    benchmark
  )

  const [surveyJson, setSurveyJson] = useState()
  const [showResultScreen, setShowResultScreen] = useState(false)
  const [viewportRef, inViewport] = useInView({
    threshold: [0.5],
    root: document.body,
  })

  useEffect(() => {
    const body = new FormData()
    body.append('Id', data.surveyscreen.survey)

    fetch(GET_SURVEY_URL, {
      method: 'POST',
      body,
    })
      .then((res) => res.json())
      .then((json) => {
        let surveyJsonParsed = {}

        surveyJsonParsed = JSON.parse(
          json.json
            // eslint-disable-next-line no-useless-escape
            .replace(/\\\"/g, '"')
            .replace(/\\\\/g, '\\')
            .replace(/\\'/g, "'")
        )

        setSurveyJson(surveyJsonParsed)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onComplete = async (result) => {
    const resultData = JSON.stringify(result.valuesHash)

    const body = new FormData()

    body.append('SurveyId', 1)
    body.append('Json', resultData)

    const res = await fetch(POST_RESULT_URL, {
      method: 'POST',
      body,
    })

    // eslint-disable-next-line no-unused-vars
    const text = await res.text()

    setShowResultScreen(true)
  }

  const bodyClassNames = cls('benchmark__body', {
    'benchmark__body--visible': inViewport,
  })

  return (
    <div className='feed__item-body'>
      <div className='feed__item-benchmark'>
        <div
          className='benchmark'
          ref={viewportRef}
          style={{
            backgroundImage: `url(${
              data.surveyscreen.image.mediaDetails.sizes.find(
                (image) => image.name === 'jl-9-16'
              ).sourceUrl
            }`,
            backgroundRepeat: 'no-repeat',
          }}
        >
          <h1>Benchmark</h1>
          <div className={bodyClassNames}>
            {surveyJson && !showResultScreen && (
              <Survey json={surveyJson} onComplete={onComplete} />
            )}
            {showResultScreen && (
              <div className='feed__item-benchmark-result-screen'>
                <h2>{data.resultscreen.headline}</h2>
                <img
                  height={200}
                  width={200}
                  src={
                    data.resultscreen.image.mediaDetails.sizes.find(
                      (image) => image.name === 'jl-1-1'
                    ).sourceUrl
                  }
                  alt=''
                />
                <p>{data.resultscreen.text}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedItemBenchmark
