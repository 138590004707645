import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-hook-inview'
import { useLongPress } from 'use-long-press'
import VideoPlayer from '../VideoPlayer'
import Modal from 'react-modal'

import { graphql, useFragment } from 'react-relay'

const FeedItemVideo = ({ video }) => {
  const data = useFragment(
    graphql`
      fragment FeedItemVideo_video on How2Post_How2postdata_Video {
        video {
          muxPlaybackId
        }

        relatedarticles {
          ... on Post {
            title
            slug
            featuredImage {
              node {
                mediaDetails {
                  sizes {
                    name
                    sourceUrl
                  }
                }
              }
            }
          }
        }

        cta {
          ctatype
          linkcta {
            buttonlabel
            modalheadline
            modalbuttonlabel
            oevburl
            vghurl
          }
          contactcta {
            buttonlabel
            modalheadline
            modalbuttonlabel
          }
        }
      }
    `,
    video
  )

  const videoRef = useRef()
  const [viewportRef, inViewport] = useInView({
    threshold: [0.5],
    root: document.body,
  })

  const [videoEnded, setVideoEnded] = useState(false)
  const [videoMuted, setVideoMuted] = useState(true)
  // const [videoPaused, setVideoPaused] = useState(false)

  const handleReplay = () => {
    setVideoEnded(false)
    videoRef.current.play()
  }

  const handleMute = () => {
    if (videoRef.current.isMuted()) {
      videoRef.current.unmute()
      setVideoMuted(false)
    } else {
      videoRef.current.mute()
      setVideoMuted(true)
    }
  }

  useEffect(() => {
    if (inViewport) {
      videoRef.current.play()
    } else {
      videoRef.current.reset()
    }
  }, [inViewport])

  const onLongPress = useCallback(() => {
    videoRef.current.pause()
  }, [])

  const longPressBind = useLongPress(videoEnded ? null : onLongPress, {
    onFinish: () => videoRef.current.play(),
    threshold: 200,
    captureEvent: true,
    cancelOnMovement: false,
    detect: 'both',
  })

  const handlePlay = () => {
    setVideoEnded(false)
    // setVideoPaused(false)
  }

  const handlePause = () => {
    // setVideoPaused(true)
  }

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentLabel='Example Modal'
        ariaHideApp={false}
      >
        {data.cta.ctatype === 'contactCta' && (
          <div>
            <h2>{data.cta.contactcta.modalheadline}</h2>
            <br />
            <input placeholder='Beratersuche PLZ / Ort' />
            <br />
            <button>{data.cta.contactcta.modalbuttonlabel}</button>
          </div>
        )}
        {data.cta.ctatype === 'linkCta' && (
          <div>
            <h2>{data.cta.linkcta.modalheadline}</h2>
            <br />
            <a href={data.cta.linkcta.vghurl} target='_blank' rel='noreferrer'>
              {data.cta.linkcta.modalbuttonlabel}
            </a>
            <br />
            <a href={data.cta.linkcta.oevburl} target='_blank' rel='noreferrer'>
              {data.cta.linkcta.modalbuttonlabel}
            </a>
          </div>
        )}
        <button onClick={() => setModalOpen(false)}>Close</button>
      </Modal>

      <div className='feed__item-body' {...longPressBind}>
        {/* {videoPaused && !videoEnded && (
          <div className='feed__item-body-video-paused'>
            <h1>Video Paused</h1>
          </div>
        )} */}
        {videoEnded && (
          <div className='feed__item-body-video-ended'>
            <div className='feed__item-body-video-ended-related-posts'>
              {data.relatedarticles.map((post) => {
                return (
                  <a
                    key={post.slug}
                    rel='norefferer noreferrer'
                    className='feed__item-body-video-ended-related-posts-post'
                    href={`https://jetztlosleben.de/${post.slug}`}
                    target='_blank'
                  >
                    <h4>{post.title}</h4>
                    <img
                      height={100}
                      width={100}
                      alt=''
                      src={
                        post.featuredImage.node.mediaDetails.sizes.find(
                          (image) => image.name === 'jl-1-1'
                        ).sourceUrl
                      }
                    />
                  </a>
                )
              })}
            </div>

            <button onClick={() => setModalOpen(true)}>
              {data.cta.ctatype === 'contactCta' && (
                <>{data.cta.contactcta.buttonlabel}</>
              )}
              {data.cta.ctatype === 'linkCta' && (
                <>{data.cta.linkcta.buttonlabel}</>
              )}
            </button>

            <button onClick={handleReplay}>Replay</button>
          </div>
        )}
        {!videoEnded && (
          <div className='feed__item-body-video-controls'>
            <button onClick={handleMute}>
              {videoMuted ? 'Unmute' : 'Mute'}
            </button>
          </div>
        )}
      </div>
      <div className='feed__item-video' ref={viewportRef}>
        <VideoPlayer
          ref={videoRef}
          onEnded={() => setVideoEnded(true)}
          onPlay={handlePlay}
          onPause={handlePause}
          muxPlaybackId={data.video.muxPlaybackId}
        />
      </div>
    </>
  )
}

export default FeedItemVideo
