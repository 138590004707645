import { URLHash } from '@pxwlab/splide-extension-url-hash'
import { Splide } from '@splidejs/react-splide/dist/js/react-splide.cjs'
import '@splidejs/splide/dist/css/splide.min.css'
import React, { useRef, useEffect } from 'react'
import useKeypress from 'react-use-keypress'
import useWindowSize from '../../hooks/useWindowSize'
import { useLocation } from 'react-router-dom'

import { graphql, useFragment } from 'react-relay'
import FeedPost from './FeedPost'

const splideOptions = {
  direction: 'ttb',
  pagination: false,
  height: window.innerHeight,
  gap: '0px',
  padding: '0px',
  autoHeight: false,
  preloadPages: 2,
}

const Feed = ({ path, items, category }) => {
  const data = useFragment(
    graphql`
      fragment Feed_category on How2Category {
        slug
        name
        how2Posts {
          edges {
            node {
              id
              ...FeedPost_post
            }
          }
        }
      }
    `,
    category
  )

  const splideRef = useRef()
  const { pathname } = useLocation()
  const { width, height } = useWindowSize()

  const timeoutRef = useRef()

  useEffect(() => {
    clearTimeout(timeoutRef)
    if (pathname !== path && splideRef.current.splide.index !== 0) {
      timeoutRef.current = setTimeout(() => splideRef.current.splide.go(0), 500)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useKeypress(['ArrowUp', 'ArrowDown'], (event) => {
    event.preventDefault()
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'ArrowUp':
        splideRef.current.splide.go('<')
        break
      case 'ArrowDown':
        splideRef.current.splide.go('>')
        break
    }
  })

  return (
    <Splide
      ref={splideRef}
      id={data.slug}
      className='feed'
      Extensions={{ URLHash }}
      options={{
        ...splideOptions,
        height: height ? height : splideOptions.height,
        width: width,
      }}
    >
      {data.how2Posts.edges.map((post) => (
        <FeedPost key={post.node.id} post={post.node} />
      ))}
    </Splide>
  )
}

export default Feed
