import React from 'react'
import { graphql, useFragment } from 'react-relay'
import FeedItem from './FeedItem'

import FeedItemBenchmark from './FeedItemBenchmark'
import FeedItemVideo from './FeedItemVideo'

const FeedPost = ({ post }) => {
  const data = useFragment(
    graphql`
      fragment FeedPost_post on How2Post {
        title
        slug

        how2PostData {
          benchmark {
            ...FeedItemBenchmark_benchmark
          }

          video {
            ...FeedItemVideo_video
          }
        }
      }
    `,
    post
  )

  return (
    <>
      <FeedItem id={`${data.slug}_video`}>
        <FeedItemVideo video={data.how2PostData.video} />
      </FeedItem>
      <FeedItem id={`${data.slug}_benchmark`}>
        <FeedItemBenchmark benchmark={data.how2PostData.benchmark} />
      </FeedItem>
    </>
  )
}

export default FeedPost
