/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedItemBenchmark_benchmark$ref: FragmentReference;
declare export opaque type FeedItemBenchmark_benchmark$fragmentType: FeedItemBenchmark_benchmark$ref;
export type FeedItemBenchmark_benchmark = {|
  +surveyscreen: ?{|
    +survey: ?string,
    +image: ?{|
      +mediaDetails: ?{|
        +sizes: ?$ReadOnlyArray<?{|
          +name: ?string,
          +sourceUrl: ?string,
        |}>
      |}
    |},
  |},
  +resultscreen: ?{|
    +headline: ?string,
    +text: ?string,
    +image: ?{|
      +mediaDetails: ?{|
        +sizes: ?$ReadOnlyArray<?{|
          +name: ?string,
          +sourceUrl: ?string,
        |}>
      |}
    |},
  |},
  +$refType: FeedItemBenchmark_benchmark$ref,
|};
export type FeedItemBenchmark_benchmark$data = FeedItemBenchmark_benchmark;
export type FeedItemBenchmark_benchmark$key = {
  +$data?: FeedItemBenchmark_benchmark$data,
  +$fragmentRefs: FeedItemBenchmark_benchmark$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "MediaItem",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MediaDetails",
      "kind": "LinkedField",
      "name": "mediaDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MediaSize",
          "kind": "LinkedField",
          "name": "sizes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sourceUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedItemBenchmark_benchmark",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "How2Post_How2postdata_Benchmark_Surveyscreen",
      "kind": "LinkedField",
      "name": "surveyscreen",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "survey",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "How2Post_How2postdata_Benchmark_Resultscreen",
      "kind": "LinkedField",
      "name": "resultscreen",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "headline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "How2Post_How2postdata_Benchmark",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '12a900f09c5bd4fee7e1db7864db905e';

module.exports = node;
