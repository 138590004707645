var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";
import { matchPath, withRouter } from "react-router";
import generatePath from "./generatePath";

var SwipeableRoutes = function (_Component) {
  _inherits(SwipeableRoutes, _Component);

  function SwipeableRoutes() {
    var _temp, _this, _ret;

    _classCallCheck(this, SwipeableRoutes);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      urls: {}
    }, _this.handleIndexChange = function (index, type) {
      var _React$Children$toArr = React.Children.toArray(_this.props.children)[index].props,
          path = _React$Children$toArr.path,
          defaultParams = _React$Children$toArr.defaultParams;


      var url = void 0;
      if (path.includes(":")) {
        if (path in _this.state.urls) {
          url = _this.state.urls[path];
        } else {
          // Build url with defaults
          url = generatePath(path, defaultParams);
          _this.setState(function (state) {
            var _extends2;

            return { urls: _extends({}, state.urls, (_extends2 = {}, _extends2[path] = url, _extends2)) };
          });
        }
      } else {
        url = path;
      }
      _this.historyGoTo(url);

      // Call the onChangeIndex if it's set
      if (typeof _this.props.onChangeIndex === "function") {
        _this.props.onChangeIndex(index, type);
      }
    }, _this.triggerOnChangeIndex = function (location) {
      var children = _this.props.children;

      React.Children.forEach(children, function (element, index) {
        var _element$props = element.props,
            pathProp = _element$props.path,
            exact = _element$props.exact,
            strict = _element$props.strict,
            from = _element$props.from;

        var path = pathProp || from;
        if (matchPath(location.pathname, { path: path, exact: exact, strict: strict })) {
          if (typeof _this.props.onChangeIndex === "function") {
            _this.props.onChangeIndex(index);
          }
          _this.setState(function (state) {
            var _extends3;

            return {
              urls: _extends({}, state.urls, (_extends3 = {}, _extends3[path] = location.pathname, _extends3))
            };
          });
        }
      });
    }, _this.historyGoTo = function (path) {
      var _this$props = _this.props,
          replace = _this$props.replace,
          history = _this$props.history;

      return replace ? history.replace(path) : history.push(path);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  // Trigger the location change to the route path


  SwipeableRoutes.prototype.componentDidMount = function componentDidMount() {
    var _this2 = this;

    var history = this.props.history;

    this.triggerOnChangeIndex(history.location);
    this.unlistenHistory = history.listen(function (location) {
      // When the location changes, call onChangeIndex with the route index
      _this2.triggerOnChangeIndex(location);
    });
  };

  SwipeableRoutes.prototype.componentWillUnmount = function componentWillUnmount() {
    this.unlistenHistory();
  };

  SwipeableRoutes.prototype.componentDidUpdate = function componentDidUpdate(prevProps) {
    // If index prop changed, change the location to the path of that route
    if (prevProps.index !== this.props.index) {
      var paths = React.Children.map(this.props.children, function (element) {
        return element.props.path;
      });
      this.historyGoTo(paths[this.props.index]);
    }
  };

  SwipeableRoutes.prototype.render = function render() {
    var _this3 = this;

    var _props = this.props,
        children = _props.children,
        index = _props.index,
        replace = _props.replace,
        innerRef = _props.innerRef,
        location = _props.location,
        history = _props.history,
        staticContext = _props.staticContext,
        routeMatch = _props.match,
        rest = _objectWithoutProperties(_props, ["children", "index", "replace", "innerRef", "location", "history", "staticContext", "match"]);

    // If there's no match, render the first route with no params


    var matchedIndex = 0;
    var match = void 0;
    if (index) {
      matchedIndex = index;
    } else {
      React.Children.forEach(children, function (element, index) {
        var _element$props2 = element.props,
            pathProp = _element$props2.path,
            exact = _element$props2.exact,
            strict = _element$props2.strict,
            from = _element$props2.from;

        var path = pathProp || from;

        match = matchPath(location.pathname, { path: path, exact: exact, strict: strict });
        if (match) {
          matchedIndex = index;
        }
      });
    }

    var renderableRoutes = React.Children.toArray(children).filter(function (element, index) {
      return !element.props.path.includes(":") || Boolean(element.props.defaultParams) || element.props.path in _this3.state.urls;
    });

    return React.createElement(
      SwipeableViews,
      _extends({}, rest, {
        index: matchedIndex,
        onChangeIndex: this.handleIndexChange,
        ref: innerRef
      }),
      renderableRoutes.map(function (element, index) {
        var _element$props3 = element.props,
            path = _element$props3.path,
            component = _element$props3.component,
            render = _element$props3.render,
            children = _element$props3.children;

        var props = { location: location, history: history, staticContext: staticContext };

        var match = matchPath(location.pathname, element.props);
        if (match) {
          match.type = "full";
        } else if (path in _this3.state.urls) {
          match = matchPath(_this3.state.urls[path], element.props);
          match.type = "outOfView";
        } else {
          match = matchPath(generatePath(path, element.props.defaultParams), element.props);
          match.type = "none";
        }
        props.match = match;
        props.key = path;

        // A lot of this code is borrowed from the render method of
        // Route. Why can't I just render the Route then?
        // Because Route only renders the component|render|children
        // if there's a match with the location, while here I render
        // regardless of the location.
        return component ? React.createElement(component, props) : render ? render(props) : children ? typeof children === "function" ? children(props) : !Array.isArray(children) || children.length // Preact defaults to empty children array
        ? React.Children.only(children) : null : null;
      })
    );
  };

  return SwipeableRoutes;
}(Component);

export default withRouter(SwipeableRoutes);