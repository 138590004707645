import React, { createContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useKeypress from 'react-use-keypress'

const AppContext = createContext()

const AppContextProvider = ({ children, routes }) => {
  const { pathname } = useLocation()

  const history = useHistory()

  if (window.location.pathname === '/' && history) {
    history.push(routes[0].path)
  }

  const handleRoutes = () => {
    const nextPotentialRouteIndex =
      routes.findIndex((route) => route.path === pathname) + 1
    const previousPotentialRouteIndex =
      routes.findIndex((route) => route.path === pathname) - 1

    const nextRoutePath =
      nextPotentialRouteIndex <= routes.length - 1
        ? routes[nextPotentialRouteIndex].path
        : null

    const previousRoutePath =
      previousPotentialRouteIndex >= 0
        ? routes[previousPotentialRouteIndex].path
        : null

    setNextRoutePath(nextRoutePath)
    setPreviousRoutePath(previousRoutePath)
  }

  useEffect(() => {
    handleRoutes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, routes])

  const [nextRoutePath, setNextRoutePath] = useState(null)
  const [previousRoutePath, setPreviousRoutePath] = useState(null)

  const goNextRoute = () => {
    if (nextRoutePath) {
      history.push(nextRoutePath)
    }
  }

  const goPreviousRoute = () => {
    if (previousRoutePath) {
      history.push(previousRoutePath)
    }
  }

  useKeypress(['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'], (event) => {
    event.preventDefault()
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'ArrowLeft':
        goPreviousRoute()
        break
      case 'ArrowRight':
        goNextRoute()
        break
    }
  })

  return (
    <AppContext.Provider
      value={{
        nextRoutePath,
        previousRoutePath,
        goNextRoute,
        goPreviousRoute,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppContextProvider }
