import React from 'react'

const LoadingScreen = () => {
  return (
    <div className='loading-screen'>
      <p>Loading...</p>
    </div>
  )
}

export default LoadingScreen
