/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedItemVideo_video$ref: FragmentReference;
declare export opaque type FeedItemVideo_video$fragmentType: FeedItemVideo_video$ref;
export type FeedItemVideo_video = {|
  +video: ?{|
    +muxPlaybackId: ?string
  |},
  +relatedarticles: ?$ReadOnlyArray<?{|
    +title?: ?string,
    +slug?: ?string,
    +featuredImage?: ?{|
      +node: ?{|
        +mediaDetails: ?{|
          +sizes: ?$ReadOnlyArray<?{|
            +name: ?string,
            +sourceUrl: ?string,
          |}>
        |}
      |}
    |},
  |}>,
  +cta: ?{|
    +ctatype: ?string,
    +linkcta: ?{|
      +buttonlabel: ?string,
      +modalheadline: ?string,
      +modalbuttonlabel: ?string,
      +oevburl: ?string,
      +vghurl: ?string,
    |},
    +contactcta: ?{|
      +buttonlabel: ?string,
      +modalheadline: ?string,
      +modalbuttonlabel: ?string,
    |},
  |},
  +$refType: FeedItemVideo_video$ref,
|};
export type FeedItemVideo_video$data = FeedItemVideo_video;
export type FeedItemVideo_video$key = {
  +$data?: FeedItemVideo_video$data,
  +$fragmentRefs: FeedItemVideo_video$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buttonlabel",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modalheadline",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modalbuttonlabel",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedItemVideo_video",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MediaItem",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "muxPlaybackId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "relatedarticles",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "NodeWithFeaturedImageToMediaItemConnectionEdge",
              "kind": "LinkedField",
              "name": "featuredImage",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MediaItem",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MediaDetails",
                      "kind": "LinkedField",
                      "name": "mediaDetails",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MediaSize",
                          "kind": "LinkedField",
                          "name": "sizes",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "sourceUrl",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Post",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "How2Post_How2postdata_Video_Cta",
      "kind": "LinkedField",
      "name": "cta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctatype",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "How2Post_How2postdata_Video_Cta_Linkcta",
          "kind": "LinkedField",
          "name": "linkcta",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "oevburl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vghurl",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "How2Post_How2postdata_Video_Cta_Contactcta",
          "kind": "LinkedField",
          "name": "contactcta",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "How2Post_How2postdata_Video",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '21b535155c7d7e1c2abefb13991e8fde';

module.exports = node;
