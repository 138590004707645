import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from './AppContext'
import Button from '@pxwlab/katana-jl/dist/Button'

const DesktopNavigation = ({ routes }) => {
  const { nextRoutePath, previousRoutePath } = useContext(AppContext)

  return (
    <>
      <nav className='desktop-navigation__routes'>
        {previousRoutePath && (
          <Link to={previousRoutePath}>
            <Button label='Previous' />
          </Link>
        )}
        {nextRoutePath && (
          <Link to={nextRoutePath}>
            <Button label='Next' />
          </Link>
        )}
      </nav>
    </>
  )
}

export default DesktopNavigation
