import { SplideSlide } from '@splidejs/react-splide'
import React from 'react'

export const FEED_ITEM_TYPE = {
  video: 'video',
  benchmark: 'benchmark',
}

const FeedItem = ({ children, id }) => {
  return (
    <SplideSlide className='feed__item' data-splide-hash={id}>
      {children}
    </SplideSlide>
  )
}

export default FeedItem
