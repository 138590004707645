/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Feed_category$ref = any;
export type AppQueryVariables = {||};
export type AppQueryResponse = {|
  +how2Categories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +slug: ?string,
        +$fragmentRefs: Feed_category$ref,
      |}
    |}>
  |}
|};
export type AppQuery = {|
  variables: AppQueryVariables,
  response: AppQueryResponse,
|};
*/


/*
query AppQuery {
  how2Categories {
    edges {
      node {
        slug
        ...Feed_category
        id
      }
    }
  }
}

fragment FeedItemBenchmark_benchmark on How2Post_How2postdata_Benchmark {
  surveyscreen {
    survey
    image {
      mediaDetails {
        sizes {
          name
          sourceUrl
        }
      }
      id
    }
  }
  resultscreen {
    headline
    text
    image {
      mediaDetails {
        sizes {
          name
          sourceUrl
        }
      }
      id
    }
  }
}

fragment FeedItemVideo_video on How2Post_How2postdata_Video {
  video {
    muxPlaybackId
    id
  }
  relatedarticles {
    __typename
    ... on Post {
      title
      slug
      featuredImage {
        node {
          mediaDetails {
            sizes {
              name
              sourceUrl
            }
          }
          id
        }
      }
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
  cta {
    ctatype
    linkcta {
      buttonlabel
      modalheadline
      modalbuttonlabel
      oevburl
      vghurl
    }
    contactcta {
      buttonlabel
      modalheadline
      modalbuttonlabel
    }
  }
}

fragment FeedPost_post on How2Post {
  title
  slug
  how2PostData {
    benchmark {
      ...FeedItemBenchmark_benchmark
    }
    video {
      ...FeedItemVideo_video
    }
  }
}

fragment Feed_category on How2Category {
  slug
  name
  how2Posts {
    edges {
      node {
        id
        ...FeedPost_post
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MediaDetails",
    "kind": "LinkedField",
    "name": "mediaDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MediaSize",
        "kind": "LinkedField",
        "name": "sizes",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sourceUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "MediaItem",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buttonlabel",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modalheadline",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modalbuttonlabel",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RootQueryToHow2CategoryConnection",
        "kind": "LinkedField",
        "name": "how2Categories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RootQueryToHow2CategoryConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "How2Category",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Feed_category"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RootQueryToHow2CategoryConnection",
        "kind": "LinkedField",
        "name": "how2Categories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RootQueryToHow2CategoryConnectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "How2Category",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "How2CategoryToHow2PostConnection",
                    "kind": "LinkedField",
                    "name": "how2Posts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "How2CategoryToHow2PostConnectionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "How2Post",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "How2Post_How2postdata",
                                "kind": "LinkedField",
                                "name": "how2PostData",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "How2Post_How2postdata_Benchmark",
                                    "kind": "LinkedField",
                                    "name": "benchmark",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "How2Post_How2postdata_Benchmark_Surveyscreen",
                                        "kind": "LinkedField",
                                        "name": "surveyscreen",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "survey",
                                            "storageKey": null
                                          },
                                          (v5/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "How2Post_How2postdata_Benchmark_Resultscreen",
                                        "kind": "LinkedField",
                                        "name": "resultscreen",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "headline",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "text",
                                            "storageKey": null
                                          },
                                          (v5/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "How2Post_How2postdata_Video",
                                    "kind": "LinkedField",
                                    "name": "video",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "MediaItem",
                                        "kind": "LinkedField",
                                        "name": "video",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "muxPlaybackId",
                                            "storageKey": null
                                          },
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "relatedarticles",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v3/*: any*/),
                                              (v0/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "NodeWithFeaturedImageToMediaItemConnectionEdge",
                                                "kind": "LinkedField",
                                                "name": "featuredImage",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "MediaItem",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": (v4/*: any*/),
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "type": "Post",
                                            "abstractKey": null
                                          },
                                          {
                                            "kind": "InlineFragment",
                                            "selections": [
                                              (v2/*: any*/)
                                            ],
                                            "type": "Node",
                                            "abstractKey": "__isNode"
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "How2Post_How2postdata_Video_Cta",
                                        "kind": "LinkedField",
                                        "name": "cta",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "ctatype",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "How2Post_How2postdata_Video_Cta_Linkcta",
                                            "kind": "LinkedField",
                                            "name": "linkcta",
                                            "plural": false,
                                            "selections": [
                                              (v6/*: any*/),
                                              (v7/*: any*/),
                                              (v8/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "oevburl",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "vghurl",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "How2Post_How2postdata_Video_Cta_Contactcta",
                                            "kind": "LinkedField",
                                            "name": "contactcta",
                                            "plural": false,
                                            "selections": [
                                              (v6/*: any*/),
                                              (v7/*: any*/),
                                              (v8/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d5f6e44f2543d0ba7849f3963b28b42",
    "id": null,
    "metadata": {},
    "name": "AppQuery",
    "operationKind": "query",
    "text": "query AppQuery {\n  how2Categories {\n    edges {\n      node {\n        slug\n        ...Feed_category\n        id\n      }\n    }\n  }\n}\n\nfragment FeedItemBenchmark_benchmark on How2Post_How2postdata_Benchmark {\n  surveyscreen {\n    survey\n    image {\n      mediaDetails {\n        sizes {\n          name\n          sourceUrl\n        }\n      }\n      id\n    }\n  }\n  resultscreen {\n    headline\n    text\n    image {\n      mediaDetails {\n        sizes {\n          name\n          sourceUrl\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment FeedItemVideo_video on How2Post_How2postdata_Video {\n  video {\n    muxPlaybackId\n    id\n  }\n  relatedarticles {\n    __typename\n    ... on Post {\n      title\n      slug\n      featuredImage {\n        node {\n          mediaDetails {\n            sizes {\n              name\n              sourceUrl\n            }\n          }\n          id\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  cta {\n    ctatype\n    linkcta {\n      buttonlabel\n      modalheadline\n      modalbuttonlabel\n      oevburl\n      vghurl\n    }\n    contactcta {\n      buttonlabel\n      modalheadline\n      modalbuttonlabel\n    }\n  }\n}\n\nfragment FeedPost_post on How2Post {\n  title\n  slug\n  how2PostData {\n    benchmark {\n      ...FeedItemBenchmark_benchmark\n    }\n    video {\n      ...FeedItemVideo_video\n    }\n  }\n}\n\nfragment Feed_category on How2Category {\n  slug\n  name\n  how2Posts {\n    edges {\n      node {\n        id\n        ...FeedPost_post\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cbf96555693ae8f5341a5305a801bb45';

module.exports = node;
