// import '@pxwlab/katana-jl/dist/katana-components.css'
import React from 'react'
import { graphql } from 'react-relay'
import {
  loadQuery,
  RelayEnvironmentProvider,
  usePreloadedQuery,
} from 'react-relay/hooks'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import SwipeableRoutes from '@pxwlab/react-swipeable-routes'
import '../index.css'
import RelayEnvironment from '../relay/RelayEnvironment'
import { AppContextProvider } from './AppContext'
import DesktopNavigation from './DesktopNavigation'
import Feed from './Feed/Feed'
import LoadingScreen from './LoadingScreen'

const { Suspense } = React

const AppQuery = graphql`
  query AppQuery {
    how2Categories {
      edges {
        node {
          slug
          ...Feed_category
        }
      }
    }
  }
`

const preloadedQuery = loadQuery(RelayEnvironment, AppQuery)

function App(props) {
  const height = window.innerHeight
  const appData = usePreloadedQuery(AppQuery, props.preloadedQuery)
  const routes = appData.how2Categories.edges.map((category) => ({
    path: '/' + category.node.slug,
  }))

  return (
    <div className='app' style={{ '--app-height': `${height}px` }}>
      <Router>
        <AppContextProvider routes={routes}>
          <SwipeableRoutes replace key={routes.length}>
            {appData.how2Categories.edges.map((category) => {
              return (
                <Route path={'/' + category.node.slug} key={category.node.slug}>
                  <Feed
                    category={category.node}
                    path={'/' + category.node.slug}
                  />
                </Route>
              )
            })}
          </SwipeableRoutes>
          <DesktopNavigation routes={routes} />
        </AppContextProvider>
      </Router>
    </div>
  )
}

function AppRoot(props) {
  return (
    <RelayEnvironmentProvider environment={RelayEnvironment}>
      <Suspense fallback={<LoadingScreen />}>
        <App preloadedQuery={preloadedQuery} />
      </Suspense>
    </RelayEnvironmentProvider>
  )
}

export default AppRoot
