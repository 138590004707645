/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FeedPost_post$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Feed_category$ref: FragmentReference;
declare export opaque type Feed_category$fragmentType: Feed_category$ref;
export type Feed_category = {|
  +slug: ?string,
  +name: ?string,
  +how2Posts: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: FeedPost_post$ref,
      |}
    |}>
  |},
  +$refType: Feed_category$ref,
|};
export type Feed_category$data = Feed_category;
export type Feed_category$key = {
  +$data?: Feed_category$data,
  +$fragmentRefs: Feed_category$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Feed_category",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "How2CategoryToHow2PostConnection",
      "kind": "LinkedField",
      "name": "how2Posts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "How2CategoryToHow2PostConnectionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "How2Post",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FeedPost_post"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "How2Category",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'cea53d44a20e4ff582524278fff4e996';

module.exports = node;
