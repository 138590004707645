/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FeedItemBenchmark_benchmark$ref = any;
type FeedItemVideo_video$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedPost_post$ref: FragmentReference;
declare export opaque type FeedPost_post$fragmentType: FeedPost_post$ref;
export type FeedPost_post = {|
  +title: ?string,
  +slug: ?string,
  +how2PostData: ?{|
    +benchmark: ?{|
      +$fragmentRefs: FeedItemBenchmark_benchmark$ref
    |},
    +video: ?{|
      +$fragmentRefs: FeedItemVideo_video$ref
    |},
  |},
  +$refType: FeedPost_post$ref,
|};
export type FeedPost_post$data = FeedPost_post;
export type FeedPost_post$key = {
  +$data?: FeedPost_post$data,
  +$fragmentRefs: FeedPost_post$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedPost_post",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "How2Post_How2postdata",
      "kind": "LinkedField",
      "name": "how2PostData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "How2Post_How2postdata_Benchmark",
          "kind": "LinkedField",
          "name": "benchmark",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FeedItemBenchmark_benchmark"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "How2Post_How2postdata_Video",
          "kind": "LinkedField",
          "name": "video",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FeedItemVideo_video"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "How2Post",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '96ce845dd238989c7bd233c41f4dfce9';

module.exports = node;
