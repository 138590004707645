import Hls from 'hls.js'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'

const VideoPlayer = React.forwardRef(
  ({ loop, onEnded, onChangeMute, muxPlaybackId, ...restProps }, ref) => {
    const videoRef = useRef(null)
    // const src =
    //   'https://stream.mux.com/5Cy5Eyn2WdbueYcGxDf6nPZqw9ZJG3PVEDZwXY9B2us.m3u8'
    // const posterUrl =
    //   'https://image.mux.com/5Cy5Eyn2WdbueYcGxDf6nPZqw9ZJG3PVEDZwXY9B2us/thumbnail.png'
    const src = `https://stream.mux.com/${muxPlaybackId}.m3u8`
    const posterUrl = `https://image.mux.com/${muxPlaybackId}/thumbnail.png`
    const hlsRef = useRef()
    const [mediaAttached, setMediaAttached] = useState(false)

    const handleMediaAttach = () => {
      if (!hlsRef.current) {
        return
      }

      if (!mediaAttached) {
        const video = videoRef.current

        hlsRef.current.attachMedia(video)
        hlsRef.current.startLoad()
        setMediaAttached(true)
      }
    }

    useEffect(() => {
      if (videoRef.current) {
        const video = videoRef.current
        videoRef.current.defaultMuted = true
        videoRef.current.muted = true

        if (video.canPlayType('application/vnd.apple.mpegurl')) {
          // Some browers (safari and ie edge) support HLS natively
          video.src = src
        } else if (Hls.isSupported()) {
          // This will run in all other modern browsers
          hlsRef.current = new Hls()
          hlsRef.current.loadSource(src)
          // hls.attachMedia(video)
          // hls.on(Hls.Events.LEVEL_LOADING, () => {
          //   hls.stopLoading()
          // })
        } else {
          console.error("This is a legacy browser that doesn't support MSE")
        }
      }

      return () => {
        if (hlsRef.current) {
          hlsRef.current.destroy()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef])

    useImperativeHandle(
      ref,
      () => {
        return {
          play: () => {
            handleMediaAttach()
            videoRef.current.play()
          },
          pause: () => {
            videoRef.current.pause()
          },
          reset: () => {
            videoRef.current.pause()
            videoRef.current.currentTime = 0
          },
          mute: () => {
            videoRef.current.muted = true
            videoRef.current.defaultMuted = true
          },
          unmute: () => {
            videoRef.current.muted = false
            videoRef.current.defaultMuted = false
          },
          isMuted: () => {
            return videoRef.current.muted
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const [waiting, setWaiting] = useState(false)
    const onPlaying = () => setWaiting(false)
    const onWaiting = () => setWaiting(true)

    return (
      <div className='video' ref={ref}>
        <video
          preload='metadata'
          playsInline
          // autoPlay
          loop={loop}
          {...restProps}
          ref={videoRef}
          onEnded={onEnded}
          onPlaying={onPlaying}
          onWaiting={onWaiting}
          poster={posterUrl}
        />
        {waiting && <p className='video__loading'>Video is buffering</p>}
      </div>
    )
  }
)

export default VideoPlayer
